<template>
  <div class="card mb-4 shadow-sm course">
<!--    <h4 class="" style="text-align: center">{{honor.name}}</h4>-->
    <img v-bind:src="honor.image" class="img-fluid">
<!--    <div class="card-body">-->
<!--      <p class="card-text">知识付费时代已经到来了，这个行业具备崭新的发展空间，以实战为基础，手把手教会如何掌握一门生存技术。</p>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "theHonorCard",
  props:{
    honor:{},

  },
  data:function (){
    return{
      image : require('../assets/res/black3.png'),
    }
  },
}
</script>

<style scoped>
.course h4{
  font-size: 1.25rem;
  margin-top: 15px;
}
.course .text-muted .badge{
  font-size: 1rem;
}
</style>