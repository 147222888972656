<template>
  <div>
<!--    <div class="card mb-4 shadow-sm course">-->
<!--      <img v-bind:src="honor.image"-->
<!--           class="img-fluid">-->
<!--    </div>-->

    <div class="container">
      <div class="row">

        <div class="col-sm">
        </div>

        <div class="col-lg-4">
          <div class="card mb-4 shadow-sm course" >
            <img v-bind:src="image1"
                 class="img-fluid">
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card mb-4 shadow-sm course" >
            <img v-bind:src="image2"
                 class="img-fluid">
          </div>
        </div>
        <div class="col-sm">
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "theHonorCardTwo",
  props:{
    honor:{},

  },
  data:function (){
    return{
      // image : require('../assets/res/black2.png'),
      image1 : 'https://xuetong-course.oss-cn-beijing.aliyuncs.com/course/6weQprMFcAWEM8WUCksGww.jpg?versionId=null',
      image2 : 'https://xuetong-course.oss-cn-beijing.aliyuncs.com/course/6weQprMFcAWEM8WUCksGww.jpg?versionId=null',
    }
  },
}
</script>

<style scoped>
.course h4{
  font-size: 1.25rem;
  margin-top: 15px;
}
.course .text-muted .badge{
  font-size: 1rem;
}
</style>