<template>
  <header>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
      <div class="container">
        <router-link to="/index" class="navbar-brand" >
          <img class="nav-user-photo" v-bind:src="image" style="width: 25px;height: 25px;border-radius: 10px" />
         &nbsp; <a style="font-size: 18px">适趣儿童识字</a>
        </router-link>

        <div class="collapse navbar-collapse" id="navbarSupportedContent" >
            <div class="container" style="display: inline-block">
              <ul class="navbar-nav nav-tabs nav-justified" >
                <li class="nav-item active" style="display: inline">
<!--                  <a v-on:click="welcome" class="nav-link" >公司首页</a>-->
                  <a href="#" class="nav-link">公司首页</a>
                </li>
                <li class="nav-item active" style="display: inline">
                  <a href="#company_honor" class="nav-link">公司介绍</a>
                </li>
                <li class="nav-item active" style="display: inline">
                  <a v-on:click="aboutus" class="nav-link">联系我们</a>
                </li>
              </ul>
            </div>
        </div>
      </div>
    </nav>


  </header>
</template>

<script>
export default {
  name: "theHeader",
  data:function (){
    return{
      news:[],
      image : require('../assets/image/image.jpg')
    }
  },
  mounted() {
    let _this = this;
  },
  methods:{
    welcome(){
      let _this = this;
    },
    infos(){
      let _this = this;
    },
    aboutus(){
      let _this = this;
    }
  },
}
</script>

<style scoped>

</style>