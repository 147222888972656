<template>
  <div>
    <main role="main">
      <div class="album py-1 bg-white">
        <div class="container">

          <div class="row course-head">
            <div class="col-sm-5">
              <div class="text" style="text-align: left">
                <br>
                <br>
<!--                <br>-->
                <br>
                <h3  style="font-size:40px;font-weight: bolder">找字游戏</h3>
                <br>
               <div>
                 <p  class="lead text-muted"
                     style="font-size: 28px;
               font-weight: bolder">多次阅读后，软件会找出孩子之前不认识或者点击过的字，根据语音提示进行找字游戏</p>
               </div>
              </div>
            </div>
            <div class="col-sm"></div>
            <div class="col-sm-5 text-center" id="cover-video-div">
              <img v-bind:src="image" class="img-fluid">
            </div>
          </div>
        </div>
        <br>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "detail_four",
  data:function (){
    return{
      product:{},
      image : require('../assets/web/ddd.png'),

    }
  },
  mounted() {
    let _this = this;
    _this.init();
  },
  methods:{
    init(){
      let _this = this;
      // _this.product.name = 'test';
      // _this.product.enroll = 'test';
      // _this.product.summary = "111";
      // _this.product.price = "111";

    },
  }
}
</script>

<style scoped>

</style>