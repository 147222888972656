<template>
  <footer class="text-muted">
    <div class="container">
      <div class="title">联系我们</div>
      <p> &nbsp; 电话: 400-686-9468 09:30 - 18:30 (周一至周五,法定节假日除外)</p>
      <p>  邮箱: bd@zhilehuo.com - 商务合作</p>
      <p>  地址: 北京上地十街辉煌国际1号</p>
      <p class="float-right">
        <a href="#">返回顶部</a>
      </p>
    </div>

  </footer>
</template>

<script>
export default {
  name: "theFooter"
}
</script>

<style scoped>
.title{
  margin-bottom: 2rem;
  color: #fafafa;
  letter-spacing: 0;
  text-shadow: 0px 1px 0px #999, 0px 2px 0px #888, 0px 3px 0px #777, 0px 4px 0px #666, 0px 5px 0px #555, 0px 6px 0px #444, 0px 7px 0px #333, 0px 8px 7px #001135;
  font-size: 2rem;
}
</style>