<template>
  <div>
    <main role="main">
      <div class="album py-4 bg-white">
        <div class="container">
          <div class="row course-head">
            <div class="col-sm-5" id="cover-video-div">
              <img v-bind:src="image" class="img-fluid">
            </div>
            <div class="col-sm"></div>
            <div class="col-sm-5">
<!--              <div class="title1" style="text-align: center" >上千篇儿童读物，让识字回归阅读</div>-->
              <div class="text" style="">
                <br>
<!--                <br>-->
                <br>
                <h3  style="font-size:3rem;font-weight: bolder">自主阅读</h3>
<!--                <br>-->
                <br>
               <div>
                 <p  class="lead text-muted"
                     style="font-size: 2rem;
               font-weight: bolder">孩子在阅读过程中，遇到不认识的字可以直接点击，自动发声，完全实现自主阅读</p>

               </div>
              </div>
            </div>
          </div>
        </div>
        <br>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "detail_three",
  data:function (){
    return{
      product:{},
      image : require('../assets/web/ccc.png'),

    }
  },
  mounted() {
    let _this = this;
    _this.init();
  },
  methods:{
    init(){
      let _this = this;
      // _this.product.name = 'test';
      // _this.product.enroll = 'test';
      // _this.product.summary = "111";
      // _this.product.price = "111";

    },
  },
}
</script>

<style scoped>

</style>