<template>
    <div>
<!--      <nav class="navbar navbar-expand-md  bg-dark navbar-dark fixed-top"  id="navbar_one">-->
<!--        <div class="navbar-brand" >-->
<!--          &lt;!&ndash;            <img v-bind:src="image_small" style="" >&ndash;&gt;-->
<!--          <img class="nav-user-photo"-->
<!--               v-bind:src="image_small"-->
<!--               style="width: 60px;height: 60px;border-radius: 28px"-->
<!--               v-on:click="todo_click"/>-->
<!--          &nbsp; <a v-on:click="todo_click"-->
<!--                    style="font-size: 20px;color: #1692ee">适趣儿童识字</a></div>-->
<!--        &lt;!&ndash; // Brand部分 &ndash;&gt;-->
<!--             &lt;!&ndash; //折叠按钮 &ndash;&gt;-->
<!--             <button class="navbar-toggler navbar-expand-md bg-secondary" type="button" data-toggle="collapse"-->
<!--                     data-target="#collapsibleNavbar" >-->
<!--               <span  v-on:click="clc" class="navbar-toggler-icon" ></span>-->
<!--             </button>-->

<!--          <div class="container" style="display: inline-block">-->
<!--            &lt;!&ndash; //导航栏链接 &ndash;&gt;-->
<!--            <div class="collapse navbar-collapse" id="collapsibleNavbar">-->
<!--              <ul class="navbar-nav  nav-justified text-right" id="navbar_three">-->
<!--                <li class="nav-item active">-->
<!--                  <a class="nav-link"  style="color: white;font-size: 30px;font-weight: bolder" href="#">公司首页 </a>-->
<!--                </li>-->
<!--                <li class="nav-item active">-->
<!--                  <a class="nav-link"  style="color: white;font-size: 30px;font-weight: bolder" href="#company_info">公司介绍</a>-->
<!--                </li>-->
<!--                <li class="nav-item active">-->
<!--                  <a class="nav-link"  style="color: white;font-size: 30px;font-weight: bolder" href="#about_us">联系我们</a>-->
<!--                </li>-->
<!--              </ul>-->
<!--              </div>-->

<!--            </div>-->
<!--        -->
<!--      </nav>-->

<!--      <nav class="navbar navbar-expand-sm bg-dark navbar-dark justify-content-end fixed-top">-->
<!--        <div class="navbar-brand" >-->
<!--                    <img class="nav-user-photo"-->
<!--                         v-bind:src="image_small"-->
<!--                         style="width: 30px;height: 30px;border-radius: 15px"-->
<!--                         v-on:click="todo_click"/>-->
<!--                    &nbsp; <a v-on:click="todo_click"-->
<!--                              style="font-size: 15px;color: #1692ee">适趣儿童识字</a></div>-->

<!--        <button class="btn bg-dark ml-auto mr-1 hide"></button>-->
<!--        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">-->
<!--          <span class="navbar-toggler-icon"></span>-->
<!--        </button>-->
<!--       <div class="text-center">-->
<!--         <div class="collapse navbar-collapse flex-grow-0" id="collapsibleNavbar" >-->
<!--           <ul class="navbar-nav text-center"><li class="nav-item active">-->
<!--               <a class="nav-link"  style="color: white;font-size: 15px;font-weight: bolder" href="#">首页 </a>-->
<!--             </li>-->
<!--             <li class="nav-item active">-->
<!--               <a class="nav-link"  style="color: white;font-size: 15px;font-weight: bolder" href="#company_info">公司介绍</a>-->
<!--             </li>-->
<!--             <li class="nav-item active">-->
<!--               <a class="nav-link"  style="color: white;font-size: 15px;font-weight: bolder" href="#about_us">联系我们</a>-->
<!--             </li>-->
<!--           </ul>-->
<!--         </div>-->

<!--       </div>-->
<!--      </nav>-->

      <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
        <div class="navbar-brand" >
          <img class="nav-user-photo"
               v-bind:src="image_small"
               style="width: 30px;height: 30px;border-radius: 15px"
               v-on:click="todo_click"/>
          &nbsp; <a v-on:click="todo_click"
                    style="font-size: 15px;color: #1692ee">适趣儿童识字</a></div>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="container">
          <div class="collapse navbar-collapse" id="collapsibleNavbar" >
            <div class="container" style="display: inline-block">
              <ul class="navbar-nav  nav-justified" >
                <li class="nav-item active" style="display: inline">
                  <!--                  <a v-on:click="welcome" class="nav-link" >公司首页</a>-->
                  <a href="#" class="nav-link">公司首页</a>
                </li>
                <li class="nav-item active" style="display: inline">
                  <a href="#company_info" class="nav-link">公司介绍</a>
                </li>
                <li class="nav-item active" style="display: inline">
                  <a href="#about_us" class="nav-link">联系我们</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <main role="main">
        <br><br>

        <div class="album py-2 bg-white">
          <!--        <div class="container">-->
          <!--          <img v-bind:src="carousel" style="width: 100%;height: 100%;">-->
          <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
              <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
<!--              <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>-->
<!--              <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>-->
            </ol>
            <div class="carousel-inner">
              <div class="carousel-item active" >
                <img v-bind:src="carousel_1" class="d-block w-100" alt="...">
              </div>
<!--              <div class="carousel-item">-->
<!--                <img v-bind:src="carousel_2" class="d-block w-100" alt="...">-->
<!--              </div>-->
<!--              <div class="carousel-item">-->
<!--                <img v-bind:src="carousel_3" class="d-block w-100" alt="...">-->
<!--              </div>-->
            </div>
            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
        <br>
        <br>
        <div class="album py-1 bg-white">
          <div class="container text-center">
            <img v-bind:src="image_ayq"
                 style="width: 95%">
          </div>
        </div>

<!--        <div class="album py-1 bg-white">-->
<!--           <div class="container">-->
<!--             <div class="" style="text-align: center;color: black;font-size: 40px;font-weight: bolder">适趣AI阅读识字&#45;&#45;适合所以有趣</div>-->
<!--             <div style="text-align: center;">-->
<!--               <img v-bind:src="label_image">-->
<!--             </div>-->
<!--           </div>-->
<!--&lt;!&ndash;            <br>&ndash;&gt;-->
<!--&lt;!&ndash;            <br>&ndash;&gt;-->
<!--&lt;!&ndash;            <h4 style="text-align: center">人工智能分级阅读&#45;&#45;真正做到每个小朋友的课程不一样</h4>&ndash;&gt;-->
<!--            <br>-->
<!--&lt;!&ndash;            <br>&ndash;&gt;-->
<!--            <div class="container">-->
<!--              <p class="lead text-muted"-->
<!--                 style="font-size: 25px;-->
<!--               font-weight: bolder">-->
<!--                适趣儿童识字 &#45;&#45;&#45;&#45; 人工智能分级阅读系统，先测字，后阅读。根据不同识字情况，精准推送适合孩子阅读的原创内容，真正做到每个孩子的课程都不一样，让孩子在阅读的过程中轻松识字。-->
<!--              </p>-->
<!--              <br>-->
<!--&lt;!&ndash;              <br>&ndash;&gt;-->
<!--              <hr>-->
<!--            </div>-->
<!--&lt;!&ndash;          <div class="container">&ndash;&gt;-->
<!--&lt;!&ndash;            <br>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="row m-auto">&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="col-sm text-center">&ndash;&gt;-->
<!--&lt;!&ndash;                <img v-bind:src="image_aa"&ndash;&gt;-->
<!--&lt;!&ndash;                     class="img-fluid" v-on:click="todo_click">&ndash;&gt;-->
<!--&lt;!&ndash;                <h5 style="font-weight: bolder">测识字量</h5>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="col-sm text-center">&ndash;&gt;-->
<!--&lt;!&ndash;                <img v-bind:src="image_ab"&ndash;&gt;-->
<!--&lt;!&ndash;                     class="img-fluid" v-on:click="todo_click">&ndash;&gt;-->
<!--&lt;!&ndash;                <h5 style="font-weight: bolder">推荐绘本</h5>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="col-sm text-center">&ndash;&gt;-->
<!--&lt;!&ndash;                <img v-bind:src="image_ac"&ndash;&gt;-->
<!--&lt;!&ndash;                     class="img-fluid" v-on:click="todo_click">&ndash;&gt;-->
<!--&lt;!&ndash;                <h5 style="font-weight: bolder">自主阅读</h5>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->

<!--&lt;!&ndash;              <div class="col-sm text-center">&ndash;&gt;-->
<!--&lt;!&ndash;                <img v-bind:src="image_ad"&ndash;&gt;-->
<!--&lt;!&ndash;                     class="img-fluid" v-on:click="todo_click">&ndash;&gt;-->
<!--&lt;!&ndash;                <h5 style="font-weight: bolder">找字游戏</h5>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="col-sm text-center">&ndash;&gt;-->
<!--&lt;!&ndash;                <img v-bind:src="image_ae"&ndash;&gt;-->
<!--&lt;!&ndash;                     class="img-fluid" v-on:click="todo_click">&ndash;&gt;-->
<!--&lt;!&ndash;                <h5 style="font-weight: bolder">更新推荐绘本</h5>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="col-sm text-center">&ndash;&gt;-->
<!--&lt;!&ndash;                <img v-bind:src="image_af"&ndash;&gt;-->
<!--&lt;!&ndash;                     class="img-fluid" v-on:click="todo_click">&ndash;&gt;-->
<!--&lt;!&ndash;                <h5 style="font-weight: bolder">复习</h5>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="col-sm-6">&ndash;&gt;-->
<!--&lt;!&ndash;              <br>&ndash;&gt;-->
<!--&lt;!&ndash;              <br>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <hr>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--        </div>-->

<!--        &lt;!&ndash;echart表格&ndash;&gt;-->
<!--        <div class="album py-1 bg-white">-->
<!--          <div class="container">-->
<!--            <div-->
<!--                 id="main"-->
<!--                 style="height:400px;"></div>-->
<!--            <hr>-->
<!--          </div>-->
<!--        </div>-->

<!--6大-->
        <div class="album py-1 bg-white">
          <br>
          <br>

          <div class="container">
                 <div style="text-align: center;">
                   <img v-bind:src="label_image_2" style="width: 78%">
                 </div>
          </div>
          <br>
          <br>
          <div class="bg-white">
            <br>
            <div class="container text-center">
              <img v-bind:src="image_a1"
                   style="width: 95%">
            </div>
<br>
<br>
            <div class="container text-center">
              <img v-bind:src="image_a2"
                   style="width: 95%">
            </div>
            <br>
            <br>
            <div class="container text-center">
              <img v-bind:src="image_a3"
                   style="width: 95%">
            </div>
            <br>
            <br>
            <div class="container text-center">
              <img v-bind:src="image_a4"
                   style="width: 95%">
            </div>
            <br>
            <br>
            <div class="container text-center">
              <img v-bind:src="image_a5"
                   style="width: 95%">
            </div>
            <br>
            <br>
            <div class="container text-center">
              <img v-bind:src="image_a6"
                   style="width: 95%">
            </div>
<!--            <br>-->
          </div>

        </div>


<!--        <div class="album py-1 bg-white">-->
<!--          <br>-->
<!--           <div class="container">-->
<!--&lt;!&ndash;             <h3>适趣AI阅读识字&#45;&#45;适合所以有趣</h3>&ndash;&gt;-->
<!--&lt;!&ndash;             <div style="text-align: center">&ndash;&gt;-->
<!--&lt;!&ndash;               <img v-bind:src="label_image_2">&ndash;&gt;-->
<!--&lt;!&ndash;             </div>&ndash;&gt;-->
<!--             <div class="" style="text-align: center;color: black;font-size: 3rem;font-weight: bolder">AI智能推荐算法，一对一因材施教</div>-->
<!--           </div>-->
<!--          <div class="container">-->
<!--            <div class="row">-->
<!--              <detail_one></detail_one>-->
<!--            </div>-->
<!--            <hr>-->

<!--            <div class="row">-->
<!--              <detail_two></detail_two>-->
<!--            </div>-->
<!--            <hr>-->

<!--            <div class="row">-->
<!--              <detail_three></detail_three>-->
<!--            </div>-->
<!--            <hr>-->

<!--            <div class="row">-->
<!--              <detail_four></detail_four>-->
<!--            </div>-->
<!--            <hr>-->

<!--            <div class="row">-->
<!--              <detail_five></detail_five>-->
<!--            </div>-->
<!--            <hr>-->

<!--            <div class="row">-->
<!--              <detail_six></detail_six>-->
<!--            </div>-->
<!--            <hr>-->
<!--          </div>-->
<!--        </div>-->

        <!--我们能做到什么-->


        <div class="album py-1 bg-white">
<!--          <br>-->
          <br>
          <br>
          <div class="container">
            <div style="text-align: center">
              <img v-bind:src="label_image_3" style="width: 83%">
            </div>
<!--            <div class="" style="text-align: center;color: black;font-size: 40px;font-weight: bolder">我们可以做到什么+孩子能获得什么？</div>-->
          </div>
          <br>
<!--          <br>-->
<!--          <br>-->
          <br>
         <div class="bg-light">
           <br>
           <div class="container text-center">
             <img v-bind:src="start"
                  style="width: 95%">
           </div>
           <br>
         </div>

        </div>

<!--        <br>-->

<!--        学员反馈-->
        <div class="album py-1 bg-white">
<!--          <br>-->
          <!--          <br>-->
<!--          <br>-->
          <div class="container">
            <br>
            <div style="text-align: center">
              <img v-bind:src="label_image_4" style="width: 33%">
            </div>
<!--            <div class="" style="text-align: center;color: black;font-size: 44px;font-weight: bolder">学员反馈</div>-->
          </div>
          <br>
<!--          <br>-->
          <!--          <br>-->
          <br>

          <div class="container text-center">
            <img v-bind:src="fan_kuiinfo"
                 style="width: 95%">
          </div>

        </div>

        <br>
        <br>
        <br>
<!--        <br>-->

        <!--创始人介绍-->
<!--        <founder-introduction></founder-introduction>-->

        <div class="album py-2 bg-white" >
          <div class="text-center" >
            <img v-bind:src="introduction"
            style="width: 98%; height: 100%">
          </div>
          <br id="company_info" >
        </div>

        <br >
        <br>


        <div class="album py-3 bg-white" >
          <div class="container">
                 <div style="text-align: center">
                   <img v-bind:src="title_image" style="width: 34%">
                 </div>
          </div>
          <br id="">
          <br>
          <br>
<!--          <br>-->
          <div class="container text-center">
            <img v-bind:src="image_gsjs"
                 style="width: 100%">
          </div>
        </div>

<!--        公司-->
<!--        <div class="album py-3 bg-white" >-->
<!--         <div class="container">-->
<!--&lt;!&ndash;           <div style="text-align: center">&ndash;&gt;-->
<!--&lt;!&ndash;             <img v-bind:src="title_image">&ndash;&gt;-->
<!--&lt;!&ndash;           </div>&ndash;&gt;-->
<!--           <div class="" style="text-align: center;color: black;font-size: 44px;font-weight: bolder">公司介绍</div>-->
<!--         </div>-->
<!--          <div class="container" id="company_info">-->
<!--&lt;!&ndash;            <div class="title1" style="text-align: center">&ndash;&gt;-->
<!--&lt;!&ndash;              公司介绍&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <section class="jumbotron text-left">&ndash;&gt;-->
<!--&lt;!&ndash;            <br>&ndash;&gt;-->
<!--&lt;!&ndash;            <br>&ndash;&gt;-->
<!--            <br>-->
<!--            <p class="lead text-muted"-->
<!--               style="text-indent: 2em;-->
<!--               font-size: 28px;-->
<!--                font-weight: bolder ">-->
<!--              公司是一家专注于“专业孕期知识和服务”、“AI儿童教育”的互联网公司。受到国家政府、主流媒体和知名投资人高度认可的诚信企业，创始之初，-->
<!--              即获得新东方创始人俞敏洪、著名投 资银行家盛希泰、北京大学人工智能中心主任雷鸣的联合投资，迄今已获3轮投资。-->
<!--              曾被CCTV和多家报纸媒体报道，并被政府评定为“国家高新技术企业”、中关村管委会“雏鹰计划”重点扶 持企业、北京市政府“科技创新基金”等，-->
<!--              并受中国品牌创新发展工程推荐，登上纽约时代广场 纳斯达克大屏展播一周。-->
<!--            </p>-->
<!--            <p class="lead text-muted"-->
<!--               style="text-indent: 2em;-->
<!--               font-size: 28px;-->
<!--                font-weight: bolder ">-->
<!--              公司斥资千万打造智能化“半米.线上孕妇学校”“半米.线上育儿学校”，为医院提供便利的在线孕期及育儿健康教育系统。迄今已服务18000+医院。-->
<!--            </p>-->

<!--            <p class="lead text-muted"-->
<!--               style="text-indent: 2em;-->
<!--               font-size: 28px;-->
<!--                font-weight: bolder ">-->
<!--              目前，公司的核心研发团队以“适趣儿童识字”为主要业务方向，希望通过人工智能和有趣内容的结合，大幅提前国内孩子自主阅读年龄，-->
<!--              同时，希望借助此产品，助力海外中华文化的传播。该项目已服务超过100000用户。在国内外受到广大用户的喜爱。-->
<!--            </p>-->

<!--&lt;!&ndash;            <p class="lead text-muted"&ndash;&gt;-->
<!--&lt;!&ndash;               style="text-indent: 2em;&ndash;&gt;-->
<!--&lt;!&ndash;               font-size: 22px;&ndash;&gt;-->
<!--&lt;!&ndash;                font-family:'华文宋体' ;font-weight: bolder">&ndash;&gt;-->

<!--&lt;!&ndash;            </p>&ndash;&gt;-->
<!--&lt;!&ndash;            </section>&ndash;&gt;-->
<!--            <br>-->
<!--            <hr>-->
<!--          </div>-->
<!--        </div>-->

        <br>
        <br>
        <br>

        <div class="album py-2 bg-white" id="company_honor">
        <div class="container">
          <div style="text-align: center">
            <img v-bind:src="honour_image" style="width: 44%">
          </div>
<!--          <div class="" style="text-align: center;color: black;font-size: 40px;font-weight: bolder">公司成就与荣誉</div>-->
        </div>

          <br>
          <br>
          <br>
          <div class="container text-center">
              <img v-bind:src="honour_image_show"
                   style="width: 95%">
          </div>

<!--          <div class="container" >-->
<!--&lt;!&ndash;            <div class="title1" style="text-align: center">公司成就与荣誉</div>&ndash;&gt;-->
<!--            <br>-->

<!--            <p class="lead text-muted"-->
<!--               style=" font-size: 22px;-->
<!--                font-family:'华文宋体';font-weight: bolder ">-->
<!--             2020年12月 北京智乐活科技“适趣儿童识字”获得科教好产品行业创新奖-->
<!--            </p>-->


<!--            <p class="lead text-muted"-->
<!--               style=" font-size: 22px;-->
<!--                font-family:'华文宋体';font-weight: bolder ">-->
<!--              2019年04月 田敬博士获得2019年民办教育家精神奋进奖-->
<!--            </p>-->

<!--            <p class="lead text-muted"-->
<!--               style=" font-size: 22px;-->
<!--                font-family:'华文宋体';font-weight: bolder ">-->
<!--              2019年04月 半米·线上孕妇学校获得2019年最具公信力孕育教育平台-->
<!--            </p>-->

<!--            <p class="lead text-muted"-->
<!--               style=" font-size: 22px;-->
<!--                font-family:'华文宋体' ;font-weight: bolder">-->
<!--              2018年07月 线上孕校进入全国10000家医院，累计用户超过100万-->
<!--            </p>-->


<!--            <p class="lead text-muted"-->
<!--               style=" font-size: 22px;-->
<!--                font-family:'华文宋体';font-weight: bolder ">-->
<!--              2018年04月《人民周刊》报道了“线上孕妇学校”-->
<!--            </p>-->

<!--            <p class="lead text-muted"-->
<!--               style=" font-size: 22px;-->
<!--                font-family:'华文宋体';font-weight: bolder ">-->
<!--              2018年01月 新华社半月谈报道了“线上孕妇学校”-->
<!--            </p>-->

<!--            <p class="lead text-muted"-->
<!--               style=" font-size: 22px;-->
<!--                font-family:'华文宋体';font-weight: bolder ">-->
<!--              2017年09月 “认真生孩子”登陆华尔街纳斯达克大屏-->
<!--            </p>-->

<!--            <p class="lead text-muted"-->
<!--               style=" font-size: 22px;-->
<!--                font-family:'华文宋体' ;font-weight: bolder">-->
<!--              2017年08月 280集动画制作完成-->
<!--            </p>-->

<!--            <p class="lead text-muted"-->
<!--               style=" font-size: 22px;-->
<!--                font-family:'华文宋体';font-weight: bolder ">-->
<!--              2017年08月 主题曲“认真生孩子”MV发布-->
<!--            </p>-->

<!--            <p class="lead text-muted"-->
<!--               style=" font-size: 22px;-->
<!--                font-family:'华文宋体';font-weight: bolder ">-->
<!--              2016年06月 CEO田敬录制CCTV节目，对话央视名嘴阿丘-->
<!--            </p>-->

<!--            <p class="lead text-muted"-->
<!--               style=" font-size: 22px;-->
<!--                font-family:'华文宋体';font-weight: bolder ">-->
<!--              2015年09月 半米孕期项目正式启动-->
<!--            </p>-->

<!--            <p class="lead text-muted"-->
<!--               style=" font-size: 22px;-->
<!--                font-family:'华文宋体';font-weight: bolder ">-->
<!--              2015年12月 获酷我音乐创始人雷鸣和洪泰基金联合投资-->
<!--            </p>-->


<!--            <br>-->
<!--            <div class="row">-->
<!--              <div v-for="o in honors1" class="col-md-4">-->
<!--                <the-honor-card v-bind:honor="o"></the-honor-card>-->
<!--              </div>-->
<!--              <the-honor-card-two></the-honor-card-two>-->
<!--            </div>-->
<!--            <hr>-->
<!--          </div>-->
        </div>
      </main>

      <br>

      <div class="album py-1 bg-light" id="about_us">
        <br>
        <br>
        <div class="container text-left">
          <img v-bind:src="image_lxwm"
               style="width: 80%">
        </div>
        <br>
        <br>
<!--        <div class="container">-->
<!--&lt;!&ndash;          <div class="title1" style="text-align: center">联系我们</div>&ndash;&gt;-->
<!--          <h1 style="font-weight: bolder">联系我们</h1>-->
<!--&lt;!&ndash;          <div class="title1" style="text-align: left">联系我们</div>&ndash;&gt;-->
<!--          <br>-->
<!--          <div style="font-size: 22px">-->
<!--            <p>  电话: 400-686-9468 09:30 - 18:30 (周一至周五,法定节假日除外)</p>-->
<!--            <p>  邮箱: bd@zhilehuo.com - 商务合作</p>-->
<!--            <p>  地址: 北京上地十街辉煌国际1号</p>-->
<!--          </div>-->
<!--&lt;!&ndash;          <p class="float-right">&ndash;&gt;-->
<!--&lt;!&ndash;            <a href="#">返回顶部</a>&ndash;&gt;-->
<!--&lt;!&ndash;          </p>&ndash;&gt;-->
<!--        </div>-->
      </div>

    </div>
</template>

<script>

import Detail_one from "@/components/detail_one";
import Detail_two from "@/components/detail_two";
import Detail_three from "@/components/detail_three";
import Detail_four from "@/components/detail_four";
import Detail_five from "@/components/detail_five";
import Detail_six from "@/components/detail_six";
import TheHeader from "@/components/the-header";
import TheFooter from "@/components/the-footer";
import TheHonorCard from "@/components/the-honor-card";
import TheHonorCardTwo from "@/components/the-honor-card-two";
import FounderIntroduction from "@/components/founder-introduction";
export default {
  name: "index",
  components: {
    FounderIntroduction,
    TheHonorCardTwo,
    TheHonorCard,
    TheFooter, TheHeader, Detail_six, Detail_five, Detail_four, Detail_three, Detail_two, Detail_one},
  data:function (){
    return{

      classObeject:{

      },

      honors1:[],
      honors2:[],
      // carousel_1 : 'https://images0.zaijiawan.com/shiqu/web/common/guanwang/banner.png@!ori',

      carousel_1 : require('../assets/web/banner.png'),

      // carousel_1 : require('../../public/pic/banner.png'),
      // carousel_2 : require('../assets/web/banner.png'),
      // carousel_3 : require('../assets/web/banner.png'),

      // image_small : require('../assets/web/logo2.png'),
      image_small : 'https://images0.zaijiawan.com/shiqu/web/common/icon.png@!ori',

      title_image : require('../assets/web/tab5.png'),
      // title_image : require('../../public/pic/tab5.png'),

      // honour_image : require('../../public/pic/tab6.png'),
      honour_image : require('../assets/web/tab6.png'),

      honour_image_show : 'https://images0.zaijiawan.com/shiqu/web/common/guanwang/honour_show.png@!ori',

      label_image : require('../assets/web/tab1.png'),
      label_image_2 : 'https://images0.zaijiawan.com/shiqu/web/common/guanwang/head_2.png@!ori',
      label_image_3 : 'https://images0.zaijiawan.com/shiqu/web/common/guanwang/head_4.png@!ori',
      label_image_4 : 'https://images0.zaijiawan.com/shiqu/web/common/guanwang/head_3.png@!ori',

      // start : require('../../public/pic/fivewe.png'),
      start : require('../assets/web/fivewe.png'),

      fan_kuiinfo : 'https://images0.zaijiawan.com/shiqu/web/common/guanwang/fankuiinfo.png@!ori',


      image_aa : require('../assets/label/aa.png'),
      image_ab : require('../assets/label/ab.png'),
      image_ac : require('../assets/label/ac.png'),
      image_ad : require('../assets/label/ad.png'),
      image_ae : require('../assets/label/ae.png'),
      image_af : require('../assets/label/af.png'),

      // introduction : 'https://images0.zaijiawan.com/shiqu/web/common/guanwang/startperson.png@!ori',
      introduction : require('../assets/head/personinfo.png'),
      // introduction : require('../../public/pic/personinfo.png'),


      image_a1 : 'https://images0.zaijiawan.com/shiqu/web/common/guanwang/content_1.png@!ori',
      image_a2 : 'https://images0.zaijiawan.com/shiqu/web/common/guanwang/content_2.png@!ori',
      image_a3 : 'https://images0.zaijiawan.com/shiqu/web/common/guanwang/content_3.png@!ori',
      image_a4 : 'https://images0.zaijiawan.com/shiqu/web/common/guanwang/content_4.png@!ori',
      image_a5 : 'https://images0.zaijiawan.com/shiqu/web/common/guanwang/content_5.png@!ori',
      image_a6 : 'https://images0.zaijiawan.com/shiqu/web/common/guanwang/content_6.png@!ori',

      // image_ayq : require('../assets/web/适趣哎阅读.png'),
      image_ayq : 'https://images0.zaijiawan.com/shiqu/web/common/guanwang/content_read.png@!ori',
      image_gsjs : 'https://images0.zaijiawan.com/shiqu/web/common/guanwang/content_company.png@!ori',
      image_lxwm : 'https://images0.zaijiawan.com/shiqu/web/common/guanwang/content_contact.png@!ori',

      isShow : false,

    }
  },
  mounted() {
    let _this = this;
    window.addEventListener('scroll', this.handleScroll)
    // 基于准备好的dom，初始化echarts实例
    // let myChart = _this.$echarts.init(
    //     document.getElementById("main"));
    // 指定图表的配置项和数据
    // let option = {
    //   title: {
    //     text: '全部',
    //     subtext: '识字量'
    //   },
    //   grid:{
    //     left: '5%',   // 与容器左侧的距离
    //     right: '5%', // 与容器右侧的距离
    //     //top: '5%',   // 与容器顶部的距离
    //     //bottom: '5%', // 与容器底部的距离
    //   },
    //   tooltip: {
    //     trigger: 'axis'
    //   },
    //   legend: {
    //     data: ['识字量', '累计阅读字数']
    //   },
    //   toolbox: {
    //     show: true,
    //     feature: {
    //       // dataZoom: {
    //       //   yAxisIndex: 'none'
    //       // },
    //       // dataView: {readOnly: false},
    //       magicType: {type: ['line', 'bar']},
    //       // magicType: {type: ['bar', 'line']},
    //       restore: {},
    //       saveAsImage: {}
    //     }
    //   },
    //   xAxis: {
    //     type: 'category',
    //     boundaryGap: false,
    //     data: ['06-02', '07-21', '09-15', '11-10', '12-24', '01-05', '03-02']
    //   },
    //   yAxis: [
    //     {
    //       type: 'value',
    //       axisLabel: {
    //         formatter: '{value}'
    //       }
    //     }, {
    //       type: 'value',
    //       axisLabel: {
    //         formatter: '{value}'
    //       }
    //     }],
    //   series: [
    //     {
    //       name: '识字量',
    //       type: 'line',
    //       data: [265, 545, 825, 1105, 1233, 1385, 1665],
    //       markPoint: {
    //         data: [
    //           {type: 'max', name: '最大值'},
    //           {type: 'min', name: '最小值'}
    //         ]
    //       },
    //       // markLine: {
    //       //   data: [
    //       //     {type: 'average', name: '平均值'}
    //       //   ]
    //       // }
    //     },
    //     {
    //       name: '累计阅读字数',
    //       type: 'line',
    //       data: [0, 30424, 60848, 91272, 100100, 120000, 152000],
    //       markPoint: {
    //         data: [
    //           // {name: '周最低', value: -2, xAxis: 1, yAxis: -1.5}
    //           {type: 'max', name: '最大值'},
    //           {type: 'min', name: '最小值'}
    //         ]
    //       },
    //       yAxisIndex : 1,
    //       markLine: {
    //         data: [
    //           // {type: 'average', name: '平均值'},
    //           // [{
    //           //   symbol: 'none',
    //           //   x: '90%',
    //           //   yAxis: 'max'
    //           // }, {
    //           //   symbol: 'circle',
    //           //   label: {
    //           //     position: 'start',
    //           //     formatter: '最大值'
    //           //   },
    //           //   type: 'max',
    //           //   name: '最高点'
    //           // }]
    //         ]
    //       }
    //     }
    //   ]
    // };
    // 使用刚指定的配置项和数据显示图表。
    // myChart.setOption(option);

    // _this.listAllCourse();
  },

  unmounted () {
    let _this = this;
    // 开启滚动监听功能
    window.removeEventListener('scroll', _this.isScroll)
  },

  methods:{
    // listAllCourse(){
    // let _this = this;
    // let one =  {};
    // one.name = "荣誉一";
    // one.image = 'https://xuetong-course.oss-cn-beijing.aliyuncs.com/course/6weQprMFcAWEM8WUCksGww.jpg?versionId=null';
    //
    // let two =  {};
    // two.image = 'https://xuetong-course.oss-cn-beijing.aliyuncs.com/course/6weQprMFcAWEM8WUCksGww.jpg?versionId=null';
    // two.name = "荣誉二";
    //
    // let three =  {};
    // three.image = 'https://xuetong-course.oss-cn-beijing.aliyuncs.com/course/6weQprMFcAWEM8WUCksGww.jpg?versionId=null';
    // three.name = "荣誉三";
    // _this.honors1.push(one);
    // _this.honors1.push(two);
    // _this.honors1.push(three);
    //
    //   _this.honors2.push(one);
    //   // _this.honors2.push(two);
    // },
    // shiqu_click(){
    //   let _this = this;
    //   _this.$router.push("#");
    // },
    todo_click(){
      let _this = this;
      // console.log("here!");
      // Toast.success("因为适合所以有趣")
    },
    clc(){

    },
    drawChart() {
    },

    handleScroll () { //改变元素#searchBar的top值
      let _this = this;
      // 加载js文件
      $.getScript('../static/js/control.js');
      // console.log(111);
      // $('#navbar_one').
      // $("#navbar_one").collapse('hide');
      // if ($(window).width() < 768) {
      //   //点击导航链接之后，把导航选项折叠起来
      //   $("#navbar a").click(function () {
      //     $("#navbar").collapse('hide');
      //   });
      //   //滚动屏幕时，把导航选项折叠起来
      //   $(window).scroll(function () {
      //     $("#navbar").collapse('hide');
      //   });
      // }
    },

  },


}
</script>

<style>
.title1{
  margin-bottom: 2rem;
  color: #fafafa;
  letter-spacing: 0;
  text-shadow: 0px 1px 0px #999, 0px 2px 0px #888, 0px 3px 0px #777, 0px 4px 0px #666, 0px 5px 0px #555, 0px 6px 0px #444, 0px 7px 0px #333, 0px 8px 7px #001135;
  font-size: 3rem;
}
.title2{
  margin-bottom: 2rem;
  color: transparent;
  -webkit-text-stroke: 1px black;
  letter-spacing: 0.04em;
  font-size: 3rem;
}
</style>
