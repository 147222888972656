<template>
  <div>
    <main role="main">
      <div class="album py-1 bg-white">
        <div class="container">
          <div class="row course-head">
            <div class="col-sm-4">

<!--              <div class="title1" style="text-align: center">课程内容专属定制</div>-->
              <div class="text" style="text-align: left">
                <br>
                <br>
                <br>
                <br>
                <h3  style="font-size:3rem;font-weight: bolder">推荐绘本</h3>
                <br>
<!--                <br>-->
                <div>
                  <p  class="lead text-muted"
                      style="font-size: 2rem;
               font-weight: bolder">让孩子自己主动阅读、爱读，节省大人时间
                  让孩子更自信，领跑人生</p>
                </div>

              </div>
            </div>
            <div class="col-sm">

            </div>

            <div class="col-sm-6 text-right" id="cover-video-div">
              <img v-bind:src="image" class="img-fluid">
            </div>
          </div>
        </div>
        <br>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "detail_two",
  data:function (){
    return{
      product:{},
      image : require('../assets/web/bbb.png'),

    }
  },
  mounted() {
    let _this = this;
    _this.init();
  },
  methods:{
    init(){
      let _this = this;
      // _this.product.name = 'test';
      // _this.product.enroll = 'test';
      // _this.product.summary = "111";
      // _this.product.price = "111";

    },
  }
}
</script>

<style scoped>

</style>