<template>
  <div>
    <main role="main">
      <div class="album ">
<!--        <div class="container">-->
<!--          <div class="title1" style="text-align: center">创始人介绍</div>-->
<!--&lt;!&ndash;          <img v-bind:src="title_image">&ndash;&gt;-->
<!--          <br>-->
<!--          <div class="row course-head">-->
<!--              <div class="col-sm-4" id="cover-video-div">-->
<!--                <img v-bind:src="head_image" class="img-fluid">-->
<!--              </div>-->
<!--            <div class="col-sm">-->
<!--              <div class="text" style="text-align: left">-->
<!--                <h2>田敬博士</h2>-->
<!--                <h4 style="text-indent: 4rem">创始人/CEO</h4>-->
<!--                <br>-->
<!--                <p class=""-->
<!--                   style="font-size: 22px;-->
<!--                font-family:'华文宋体';">-->
<!--                  北京大学计算机系本科、博士-->
<!--                </p>-->

<!--                <p class=""-->
<!--                   style="font-size: 22px;-->
<!--                font-family:'华文宋体' ">-->
<!--                  “酷我音乐”创始核心成员，曾任酷我音乐技术总监。-->
<!--                </p>-->

<!--                <p class=""-->
<!--                   style="font-size: 22px;-->
<!--                font-family:'华文宋体' ">-->
<!--                  2012年开始自主创业，开发的泛娱乐类APP利用ASO技术免费获得了2500万名激活用户，营收超过1000万元，还创造过IOS总榜第4名的成绩。-->
<!--                </p>-->

<!--                <p class=""-->
<!--                   style="font-size: 22px;-->
<!--                font-family:'华文宋体' ">-->
<!--                  2013年8月田敬及合伙人创办了智乐活，公司初期项目以娱乐营收类app为主，于2015年转型互联网母婴行业，-->
<!--                </p>-->

<!--                <p class=""-->
<!--                   style="font-size: 22px;-->
<!--                font-family:'华文宋体' ">-->
<!--                  启动“半米孕期”项目。-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->

<!--          </div>-->
<!--&lt;!&ndash;          <hr>&ndash;&gt;-->
<!--        </div>-->

            <div class="carousel-item active" >
              <img v-bind:src="introduction"
              style="width: 100%;height: 800px">
            </div>
        
<!--            <br><br><br><br><br><br>-->
<!--            <br><br><br><br><br><br>-->
<!--            <br><br><br><br><br><br>-->
<!--            <br><br><br><br><br><br>-->
<!--            <br><br><br><br><br><br>-->
<!--            <br><br><br><br><br><br>-->
<!--            <br><br><br>-->
<!--        <br><br><br>-->

      </div>
    </main>
  </div>

<!--  <div>-->
<!--    <div class="carousel-item active" >-->
<!--      <img v-bind:src="introduction" class="d-block w-100" alt="...">-->
<!--    </div>-->
<!--    <br>-->
<!--    <br>-->
<!--    <br>-->
<!--    <br>-->
<!--    <br>-->
<!--    <br>-->
<!--    <br>-->
<!--    <br>-->
<!--    <br>-->
<!--    <br>-->
<!--    <br>-->
<!--    <br>-->
<!--  </div>-->
</template>

<script>
export default {
  name: "FounderIntroduction",
  data : function (){
    return{
      head_image : require('../assets/image/image.jpg'),
      title_image : require('../assets/title/founder.png'),
      introduction : require('../assets/web/startperson.png'),
    }
  },
  methods(){

  }
}
</script>

<style scoped>

</style>