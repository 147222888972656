<template>
  <div>
    <main role="main">
      <div class="album py-1 bg-white">
        <br>
        <div class="container">

          <div class="row course-head">
            <div class="col-sm-5">
              <div class="text" style="text-align: left">
                <br>
                <br>
<!--                <br>-->
<!--                <br>-->
                <h3  style="font-size:40px;font-weight: bolder">科学复习</h3>
                <br>
                <div>
                  <p  class="lead text-muted"
                      style="font-size: 28px;
               font-weight: bolder">云端记录孩子认识的每个字的时间，根据“艾宾浩斯遗忘曲线”规律安排孩子不断复习</p>
                </div>
              </div>
            </div>
            <div class="col-sm"></div>
            <div class="col-sm-5 text-center" id="cover-video-div">
              <img v-bind:src="image" class="img-fluid">
            </div>
          </div>
          <br>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "detail_six",
  data:function (){
    return{
      product:{},
      image : require('../assets/web/ggg.png'),

    }
  },
  mounted() {
    let _this = this;
    _this.init();
  },
  methods:{
    init(){
      let _this = this;
      // _this.product.name = 'test';
      // _this.product.enroll = 'test';
      // _this.product.summary = "111";
      // _this.product.price = "111";

    },
  }
}
</script>

<style scoped>

</style>