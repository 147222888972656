<template>
  <div>
    <main role="main">
      <div class="album py-3 bg-white">
        <br>
        <div class="container">
          <div class="row course-head">
            <div class="col-sm-5" id="cover-video-div">
              <img v-bind:src="image" class="img-fluid">
            </div>
            <div class="col-sm"></div>
            <div class="col-sm-5">

<!--              <div class="title1" hidden style="text-align: center">提高学习兴趣，从此爱上阅读</div>-->
              <div class="text" style="text-align: left">
                <br>
<!--                <br>-->
                <br>
                <h3  style="font-size:40px;font-weight: bolder">更新推荐绘本</h3>
                <br>
                <div>
                  <p  class="lead text-muted"
                      style="font-size: 28px;
               font-weight: bolder">软件根据“找字游戏”结果，更新孩子认识的字，再次更新推荐绘本，
                  使绘本难度永远适合孩子</p>
                </div>
              </div>

            </div>
          </div>
<!--          <hr>-->
        </div>
        <br>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "detail_five",
  data:function (){
    return{
      product:{},
      image : require('../assets/web/eee.png'),

    }
  },
  mounted() {
    let _this = this;
    _this.init();
  },
  methods:{
    init(){
      let _this = this;
      // _this.product.name = 'test';
      // _this.product.enroll = 'test';
      // _this.product.summary = "111";
      // _this.product.price = "111";

    },
  },
}
</script>

<style scoped>

</style>