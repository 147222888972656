<template>
  <div>
    <main role="main">
      <br>
      <div class="album py-1 bg-white">
        <div class="container">
          <div class="row course-head">
            <div class="col-sm-5 " id="cover-video-div">
              <img v-bind:src="image"
                   class="img-fluid" >
            </div>

            <div class="col-sm">
            </div>
            <div class="col-sm-5">
              <div class="text" style="text-align: left">
<!--                <br>-->
                <br>
                <h3 style="font-size:3rem;font-weight: bolder">测识字量</h3>
                <br>
<!--                <br>-->

                <div>
                  <p class="lead text-muted"
                  style="font-size: 2rem;
               font-weight: bolder">
                    将频率最高的100个字做成“测字游戏”通过玩游戏了解小朋友识字量</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "detail_one",
  data:function (){
    return{
      product:{},
      image : require('../assets/web/aaa.png'),

    }
  },
  mounted() {
    let _this = this;
    _this.init();
  },
  methods:{
    init(){
      let _this = this;
      // _this.product.name = 'test';
      // _this.product.enroll = 'test';
      // _this.product.summary = "111";
      // _this.product.price = "111";

    },
  },
}
</script>

<style scoped>
.title1{
  margin-bottom: 2rem;
  color: #fafafa;
  letter-spacing: 0;
  text-shadow: 0px 1px 0px #999, 0px 2px 0px #888, 0px 3px 0px #777, 0px 4px 0px #666, 0px 5px 0px #555, 0px 6px 0px #444, 0px 7px 0px #333, 0px 8px 7px #001135;
  font-size: 2rem;
}
</style>